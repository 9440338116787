<template>
    <Dialog
        :visible.sync="showDialog"
        position="center"
        :modal="true"
        :closeOnEscape="true"
        :closable="true"
        class="form-templates-edit-dialog"
        :contentStyle='{ overflow: "visible" }'
        @update:visible="closeDialog"
    >
        <template #header>
            <div class="p-mb-0 dialog-title p-px-2">{{ dialogTitle }}</div>
        </template>
        <div class="p-d-flex p-flex-column p-field p-col-12 p-py-0 p-mb-0 p-px-0">
            <label>Наименование* </label>
            <InputText
                id="system"
                type="text"
                v-model="formData.name"
                placeholder=""
                :class="v$.formData.name.$invalid && submitted ? 'p-error' : ''"
            />
            <small
                v-show="submitted"
                v-for="error in showErrors(v$.formData.name)"
                :key="error.$uid"
                class="p-error"
            >{{ error.$message }}</small>
        </div>
        <div class="p-d-flex p-flex-column p-field p-col-12 p-py-0 p-mb-0 p-px-0">
            <label>Тип*</label>
            <Dropdown
                v-model="formData.placement"
                :options="placements"
                option-value="value"
                option-label="label"
            />
        </div>
        <div v-if="formData.placement === 'formDistribution'" class="p-d-flex p-flex-column p-field p-col-12 p-py-0 p-mb-0 p-px-0">
            <label>Выберите форму</label>
            <Dropdown
                v-model="formData.form"
                :options="formList"
                option-label="name"
                data-key="id"
                class="customDropDown p-column-filter"
                :class="formError && submitted ? 'p-error' : ''"
                filter
            />
            <small v-show="submitted" class="p-error">{{ formError }}</small>
        </div>
        <div class="p-d-flex p-ai-center">
            <div class="p-d-flex p-flex-column p-field p-col-6 p-py-0 p-mb-0 p-px-0">
                <label for="calendarStart">Дата начала*</label>
                <Calendar
                    id="calendarStart"
                    class="p-mr-3 customWidth"
                    v-model="v$.formData.dateStart.$model"
                    :showIcon="true"
                    dateFormat="dd.mm.yy"
                    :locale="rusCalendareLocale"
                    :showTime="true"
                    :showOnFocus="true"
                    :class="v$.formData.dateStart.$invalid && submitted ? 'p-error' : ''"
                />
            </div>
            <div class="p-d-flex p-flex-column p-field p-col-6 p-py-0 p-mb-0 p-px-0">
                <label for="calendarStart">Дата окончания</label>
                <Calendar
                    id="calendarEnd"
                    class="p-mr-3 customWidth"
                    v-model="formData.dateEnd"
                    dateFormat="dd.mm.yy"
                    :showIcon="true"
                    :showTime="true"
                    :minDate="formData.dateStart"
                    :showOnFocus="true"
                />
            </div>
        </div>
        <small
            v-show="submitted"
            v-for="error in showErrors(v$.formData.dateStart)"
            :key="error.$uid"
            class="p-error p-errorPosition p-errorPositionAdvanced"
        >{{ error.$message }}</small>
        <div class="p-d-flex p-flex-column p-field p-col-12 p-py-0 p-mb-0 p-px-0">
            <label class="p-text-normal">Текст*</label>
            <Textarea
                v-model="v$.formData.text.$model"
                placeholder="Текст уведомления"
                rows="20"
                cols="40"
                :autoResize="false"
                :class="v$.formData.text.$invalid && submitted ? 'p-error' : ''" />
            <small
                v-show="submitted"
                v-for="error in showErrors(v$.formData.text)"
                :key="error.$uid" class="p-error"
            >{{ error.$message }}</small>
        </div>
        <div class="p-d-flex p-flex-column p-field p-col-12 p-py-0 p-mb-0 p-px-0">
            <label>Сортировка*</label>
            <InputText
                type="text"
                v-model="formData.sort"
                placeholder=""
                :class="v$.formData.sort.$invalid && submitted ? 'p-error' : ''"
            />
            <small
                v-show="submitted"
                v-for="error in showErrors(v$.formData.sort)"
                :key="error.$uid"
                :class="v$.formData.sort.$invalid && submitted ? 'p-error' : ''"
            >{{ error.$message }}</small>
        </div>
        <div class="p-field-checkbox p-d-flex p-ai-center p-field p-col-12 p-pb-3 p-pt-0 p-mb-0 p-px-2">
            <Checkbox id="binary" v-model="formData.active" :binary="true" />
            <label for="binary" class="p-my-0 p-mr-0">Активный</label>
        </div>
        <template #footer>
            <div class="p-d-flex p-jc-start">
                <Button
                    class="p-button"
                    type="button"
                    @click="saveNotification"
                >
                    <span class="p-button-label">Сохранить</span>
                    <span class="p-ink"></span>
                </Button>

                <Button class="p-button p-button-outlined" @click="closeDialog">
                    <span class="p-button-label black">Отменить</span>
                </Button>
            </div>
        </template>
    </Dialog>
</template>

<script>
import { NOTIFICATION_DIALOG_MODES, NOTIFICATION_PLACEMENTS } from '@/constants/notifications';
import Notification from '@/models/Notification';
import { useVuelidate } from '@vuelidate/core';
import { required} from '@vuelidate/validators';
import { getAllForms } from '@/api/form';
import { jsonApiListParser } from '@/main/utils/common';
import { rusCalendareLocale } from '@/main/calendareLocales';
import { createNotification, patchNotification } from '@/api/notifications';
import { v4 as uuidv4 } from 'uuid';

export default {
    name: 'notificationDialog',

    props: {
        showNotificationDialog: {
            require: true,
            type: Boolean
        },

        currentDialogMode: {
            type: String
        },

        notification: {
            type: Object
        }
    },

    setup: () => ({ v$: useVuelidate() }),

    validations() {
        return {
            formData: {
                name: {
                    required: { ...required, $message: 'Поле обязательно к заполнению' },
                },

                dateStart: {
                    required: { ...required, $message: 'Поле обязательно к заполнению' },
                },

                text: {
                    required: { ...required, $message: 'Поле обязательно к заполнению' },
                },

                sort: {
                    required: { ...required, $message: 'Поле обязательно к заполнению' },
                }
            }
        }
    },

    emits: [ 'loadingChange', 'closeNotificationDialog' ],

    data() {
        this.placements = NOTIFICATION_PLACEMENTS;
        this.rusCalendareLocale = rusCalendareLocale;

        return {
            showDialog: false,
            submitted: false,
            dialogMode: NOTIFICATION_DIALOG_MODES.CREATE.NAME,
            formData: new Notification(),
            formList: null,
            formError: null
        };
    },

    async mounted() {
        await Promise.all([
            getAllForms({
                relationShip: true,
                filter: { archive: false, active: true }
            }).then(({ forms, included }) => {
                this.formList = jsonApiListParser(forms, included);
            })
        ]);
    },

    methods: {
        async saveNotification() {
            this.submitted = true;
            this.formError = null;
            if (this.formData.placement === 'formDistribution' && !this.formData.form?.id) {
                this.formError = 'Поле обязательно к заполнению';
            }
            if (this.v$.$invalid || this.formError) {
                return;
            }

            const dataToServer = {
                data: {
                    type: 'system-notification',
                    id: this.dialogMode === 'EDIT' ? this.formData.id : uuidv4(),
                    attributes: {
                        name: this.formData.name,
                        text: this.formData.text,
                        placement: this.formData.placement,
                        sort: this.formData.sort || 0,
                        active: this.formData.active,
                        activeFrom: this.formData.dateStart,
                        activeUntil: this.formData.dateEnd
                    },
                    relationships: {}
                },
            };

            if (this.formData.form) {
                dataToServer.data.relationships.form = {
                    data: {
                        type: 'form',
                        id: this.formData.form.id,
                    },
                };
            }

            this.$emit('loadingChange', true);
            try {
                let result;
                if (this.dialogMode === 'CREATE') {
                    result = await createNotification(dataToServer);
                } else {
                    result = await patchNotification(this.formData.id, dataToServer);
                }

                if (result.message) {
                    this.$requestError(result.message);
                }
            } catch(error) {
                this.$requestError(error.message);
            } finally {
                this.$emit('loadingChange');
                this.showDialog = false;
                this.$emit('closeNotificationDialog', true);
            }
        },

        closeDialog() {
            this.showDialog = false;
            this.$emit('closeNotificationDialog');
        },

        showErrors(data) {
            return data.$errors.length ? data.$errors : data.$silentErrors;
        }
    },

    computed: {
        dialogTitle() {
            return NOTIFICATION_DIALOG_MODES[this.dialogMode].TITLE
        },
    },

    watch: {
        showNotificationDialog: {
            handler(to) {
                if (to) {
                    this.submitted = false;
                    this.showDialog = this.showNotificationDialog;
                    this.dialogMode = this.currentDialogMode;
                    this.formData = new Notification(this.notification);
                    this.formData.form = this.formList.find((form) => form.id === this.formData.form);
                }
            }
        }
    }
}
// 309
</script>

<style lang="scss" scoped>
::v-deep {
    .p-dialog {
        min-width: 840px;

        &-content {
            padding-top: 20px!important;

            .p-inputtextarea {
                resize: none;
            }

            .p-field {
                margin-top: 16px;

                label {
                    margin-bottom: 4px;
                }
            }
        }
    }
}
::v-deep .customDropDown {
    width: 432px;

    .p-dropdown-items-wrapper {
        max-height: 350px !important;
        width: 430px !important;

        .p-dropdown-item {
            div {
                white-space: normal !important;
                text-overflow: ellipsis;
                overflow: hidden;
            }
        }
    }

    & .p-dropdown-filter-icon {
        right: 0.7rem;
        top: 14px;
    }
}
</style>
