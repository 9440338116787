<template>
    <div>
        <DataTable
            v-if="notifications"
            :value="notifications"
            :paginator="true"
            :lazy="true"
            :scrollable="true"
            scroll-height="calc(100vh - 400px)"
            class="p-datatable-customers p-datatable-striped user-list"
            :class="{ showFilters: showFilters }"
            :rows="pageSize"
            :rowsPerPageOptions="rowsPerPageOptions"
            paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
            currentPageReportTemplate=""
            :totalRecords="totalRecords"
            @page="onPage"
            @sort="onSort"
            @row-dblclick="rowClicked"
            dataKey="id"
            selectionMode="single"
            :filters="filters"
            filter
            stripedRows
            resizable-columns
        >
            <template #header>
                <div class="table-header p-flex-wrap p-d-flex">
                    <div class="p-col-8 p-p-0 p-d-flex p-ai-center">
                        <h5 class="p-d-inline p-mb-0 p-pt-0">Уведомления</h5>
                    </div>
                    <div class="p-col-5 p-p-0 p-d-flex p-ai-center"></div>
                    <div class="p-col-7 p-p-0 p-d-flex p-ai-center p-jc-end table-global-search">
                        <div class="p-inputgroup p-p-0 p-d-flex table-global-search__input">
                            <span class="p-float-label">
                                <InputText
                                    type="text"
                                    v-model="filters.name"
                                    placeholder="Поиск"
                                    @input="selectedFilter"
                                />
                            </span>
                            <span class="p-inputgroup-addon"><i class="pi pi-search"></i></span>
                        </div>
                        <div class="p-p-0 p-new-btn">
                            <Button @click="openNotificationDialog('CREATE')" class="p-button">Добавить</Button>
                        </div>
                    </div>
                </div>
            </template>
            <template #empty>Уведомления не найдены.</template>
            <template #loading>Список уведомлений загружается. Пожалуйста, подождите.</template>
            <Column
                header="Наименование"
                filterField="body"
                header-class="header-name"
                body-class="body-name"
                field="name"
                :sortable="true"
            >
                <template #body="slotProps">
                    {{ slotProps.data.attributes.name }}
                </template>
                <template v-if="showFilters" #filter>
                    <InputText
                        v-show="showFilters"
                        type="text"
                        v-model="filters.name"
                        class="p-column-filter p-my-2"
                        placeholder="Поиск"
                        @input="selectedFilter"
                    />
                </template>
            </Column>
            <Column header="Тип" header-class="header-type" body-class="body-type">
                <template #body="slotProps">
                    {{ getPlacement(slotProps.data.attributes.placement).label }}
                </template>
                <template v-if="showFilters" #filter>
                    <Dropdown
                        v-model="filters.placement"
                        :options="placements"
                        option-value="value"
                        option-label="label"
                        placeholder="Поиск"
                        class="p-column-filter p-my-2"
                        :showClear="true"
                        @input="selectedFilter"
                    />
                </template>
            </Column>
            <Column header="Дата начала" header-class="header-date" body-class="body-date">
                <template #body="slotProps">
                    {{ slotProps.data.attributes.activeFrom | dateTime }}
                </template>
            </Column>
            <Column header="Дата окончания" header-class="header-date" body-class="body-date">
                <template #body="slotProps">
                    {{ slotProps.data.attributes.activeUntil | dateTime }}
                </template>
            </Column>
            <Column header="Статус" header-class="header-status" body-class="body-status">
                <template #body="slotProps">
                    <span
                        :title="getStatus(slotProps.data.attributes.active).title"
                        :style="`background: ${ getStatus(slotProps.data.attributes.active).bgColor }; padding: 4px; border-radius: 4px;`"
                    >
                        {{ getStatus(slotProps.data.attributes.active).label }}
                    </span>
                </template>
                <template #filter v-if="showFilters">
                    <Dropdown
                        v-model="filters.status"
                        :options="notificationStatuses"
                        placeholder="Поиск"
                        option-value="value"
                        option-label="label"
                        class="p-column-filter p-my-2"
                        :showClear="true"
                        @click.stop
                        @change="selectedFilter"
                    />
                </template>
            </Column>
            <Column
                header="Сорт."
                header-class="header-sort"
                body-class="body-sort"
                field="sort"
                :sortable="true"
            >
                <template #body="slotProps">
                    {{ slotProps.data.attributes.sort }}
                </template>
            </Column>
            <Column
                header=""
                header-class="user-header-actions p-text-center"
                header-style="width: 52px;"
                body-class="user-body-actions"
                body-style="width: 52px; text-align: center;"
            >
                <template #body="slotProps">
                    <div class="p-panel-header-icon p-link" @click.stop="toggleRowMenu($event, slotProps)">
                        <span class="pi pi-ellipsis-h"></span>
                    </div>
                    <Menu
                        class="redLastListElement"
                        :ref="`listMenu${ slotProps.data.id }`"
                        :model="listMenuItems"
                        :popup="true"
                        :baseZIndex="10"
                    />
                </template>
                <template #header>
                    <Button
                        icon="pi pi-filter"
                        :class="filterClasses"
                        class="p-button-rounded p-button-outlined filter-btn"
                        @click="showFilters = !showFilters"
                    />
                </template>
            </Column>
            <template #paginatorLeft></template>
        </DataTable>
        <notificationDialog
            :showNotificationDialog="showNotificationDialog"
            :currentDialogMode="currentDialogMode"
            :notification="current"
            @closeNotificationDialog="closeNotificationDialog"
        />
    </div>
</template>

<script>
import { getNotifications, deleteNotification } from '@/api/notifications';
import { mapGetters } from 'vuex';
import { requestToastHandler } from '@/main/mixins';
import notificationDialog from '@/components/notifications/dialogs/notificationDialog.vue';
import { DEFAULT_PAGE_SIZE } from '@/constants/common';
import { NOTIFICATION_PLACEMENTS, NOTIFICATION_STATUSES } from '@/constants/notifications';

export default {
    name: 'notificationsList',

    mixins: [ requestToastHandler ],

    props: {
        loading: {
            type: Boolean,
            require: false,
        },
    },

    emits: ['loadingChange'],

    data() {
        this.placements = NOTIFICATION_PLACEMENTS;
        this.notificationStatuses = NOTIFICATION_STATUSES;
        this.pageSize = DEFAULT_PAGE_SIZE;

        return {
            showFilters: false,
            showNotificationDialog: false,
            currentDialogMode: 'create',
            filters: {},
            filter: {},
            sortField: null,
            notifications: [],
            filterTimeout: null,
            currentPage: 1,
            totalRecords: null,
            current: null,
            listMenuItems: [
                {
                    label: 'Редактировать',
                    icon: 'pi pi-pencil',
                    command: () => {
                        this.openNotificationDialog('EDIT')
                    },
                    permissionAccess: []
                },
                {
                    label: 'Удалить',
                    icon: 'pi pi-trash',
                    command: () => {
                        this.$root.$emit('showAcceptDeleteDialog', {
                            acceptAction: this.removeNotification
                        })
                    },
                    class: 'remove-row',
                    permissionAccess: []
                },
            ],
        };
    },

    components: {
        notificationDialog
    },

    computed: {
        ...mapGetters([ 'rowsPerPageOptions' ]),

        filterClasses() {
            return this.showFilters ? '' : 'p-button p-component p-button-outlined';
        }
    },

    methods: {
        onPage({ page, rows }) {
            this.pageSize = rows;
            this.currentPage = page + 1;
            this.debouncedFilter();
        },

        onSort({ sortField, sortOrder }) {
            this.sortField = `${ sortOrder > 0 ? '' : '-' }${ sortField }`;
            this.debouncedFilter();
        },

        selectedFilter() {
            this.currentPage = 1;
            this.pageSize = DEFAULT_PAGE_SIZE;
            this.debouncedFilter();
        },

        debouncedFilter() {
            clearTimeout(this.filterTimeout);

            this.filterTimeout = setTimeout(async () => {
                await this.getNotifications();
            }, 1000);
        },

        rowClicked({ data }) {
            this.current = data;
            this.openNotificationDialog('EDIT');
        },

        async getNotifications() {
            this.$emit('loadingChange', true);

            let filter = {};

            if (this.filters.name && this.filters.name.length > 0) {
                filter['name'] = { $ilike: this.filters.name };
            }

            if (this.filters.placement && this.filters.placement.length > 0) {
                filter['placement'] = { $eq: this.filters.placement };
            }

            if (this.filters.status && this.filters.status.length > 0) {
                filter['active'] = { $eq: this.filters.status === 'active' }
            }

            try {
                const result = await getNotifications(this.currentPage, this.pageSize, filter, this.sortField);
                if (result.message) {
                    this.$requestError(result.message);
                    return;
                }

                const { data: { data, meta } } = result;
                this.notifications = data;
                this.totalRecords = meta.pagination.total;
            } catch (error) {
                this.$requestError(error.message);
            } finally {
                this.$emit('loadingChange');
            }
        },

        toggleRowMenu(event, slotProps) {
            const { data } = slotProps;
            if (this.current && data.id !== this.current?.id) {
                this.$refs[`listMenu${ this.current.id }`] && this.$refs[`listMenu${ this.current.id }`].hide(event);
            }
            this.current = data;
            this.$refs[`listMenu${ data.id }`].toggle(event);
        },

        openNotificationDialog(mode) {
            this.currentDialogMode = mode;
            if (mode === 'CREATE') {
                this.current = null;
            }
            this.showNotificationDialog = true;
        },

        removeNotification() {
            this.$emit('loadingChange', true);
            try {
                const result = deleteNotification(this.current.id);
                if (result.message) {
                    this.$requestError(result.message);
                    return;
                }
                this.notifications = this.notifications.filter((note) => note.id !== this.current.id);
            } catch (error) {
                this.$requestError(error.message);
            } finally {
                this.$emit('loadingChange');
            }
        },

        closeNotificationDialog(update = false) {
            this.showNotificationDialog = false;
            if (update) {
                this.getNotifications(this.currentPage, this.pageSize);
            }
        },

        getPlacement(value) {
            const placement = this.placements.find(item => item.value === value);

            return placement || {};
        },

        getStatus (active) {
            const status = this.notificationStatuses.find(item => item.value === (active ? 'active' : 'archive'));

            return status || {};
        },
    },

    async mounted() {
        await this.getNotifications(this.currentPage, this.pageSize);
    },
};
// 326
</script>

<style lang="scss" scoped>
.p-datatable {
    &-customers {
        ::v-deep {
            .p-datatable-header {
                padding-top: 0 !important;
                padding-left: 0 !important;
                border: none;
                position: relative;
            }
            .p-paginator-bottom {
                border-width: 1px 0 0 0!important;
            }
            .p-menu {
                width: min-content;
                white-space: nowrap;
            }
            .p-menuitem-link {
                .empty {
                    width: 14px;
                    height: 14px;
                    margin-right: 7px;
                }
            }
            .header {
                &-name {
                    width: 12vw;
                }
                &-type {
                    width: 8vw;
                }
                &-date {
                    width: 8vw;
                }
                &-sort {
                    width: 4vw;
                }
                &-status {
                    width: 6.25vw;
                }
            }
            .body {
                &-name {
                    width: 12vw;
                }
                &-type {
                    width: 8vw;
                }
                &-date {
                    width: 8vw;
                }
                &-sort {
                    width: 4vw;
                }
                &-status {
                    width: 6.25vw;

                    .status-value {
                        background: #FFBBB3;
                        height: 22px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        width: min-content;
                        padding: 0 4px;
                        border-radius: 4px;
                        line-height: 1rem;

                        &.active {
                            background: #B3FFBB;
                        }
                    }
                }
            }
        }
    }
}
</style>


