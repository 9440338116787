export default function(data = {}) {
    this.id = data?.id || '';
    this.name = data?.attributes?.name || '';
    this.placement = data?.attributes?.placement || 'startPage';
    this.dateStart = data?.attributes?.activeFrom ? new Date(data?.attributes?.activeFrom) : new Date();
    this.dateEnd = data?.attributes?.activeUntil ? new Date(data?.attributes?.activeUntil) : null;
    this.text = data?.attributes?.text || '';
    this.active = data?.attributes?.active || true;
    this.sort = data?.attributes?.sort || 0;
    this.form = data?.relationships?.form.data?.id || null;
}
